import { Component, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { AuthenticationService } from '../services/authentication/authentication.service';

@Component({
  selector: 'authentication-modal',
  templateUrl: './authentication-modal.component.html',
})
export class AuthenticationModal {
  @ViewChild('authenticateModal', { static: true }) public authenticateModal: ModalDirective;
  @ViewChild('authenticateModalSaveButton') button;

  public authKey: string;

  constructor(
    private authProvider: AuthenticationService
  ) {
    console.log("[AuthenticationModal] constructor called!");
  }

  show() {
    this.authenticateModal.show();
  }

  hide() {
    this.authenticateModal.hide();
  }

  saveChanges() {
    this.hide();
    this.authProvider.setAuthKey(this.authKey);
  }

  ngOnInit() {
    this.authenticateModal.onShow.subscribe(data => {
      this.authKey = "";
    });
  }

}
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { filter, take, tap } from 'rxjs/operators';
import { Router, CanActivate, CanLoad, ActivatedRouteSnapshot, RouterStateSnapshot, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { NGXLogger } from 'ngx-logger';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    private isAuthenticated = false;
    private cachedLocation: string;

    private authKey: string = "";

    constructor(
        private router: Router,
        private oidcSecurityService: OidcSecurityService,
        private httpClient: HttpClient,
        private logger: NGXLogger
    ) {
        this.initService();

        this.oidcSecurityService.onCheckSessionChanged.subscribe(data => {
            this.logger.debug('SessionChange triggered');
            this.logger.debug(data);
        });
    }


    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        return this.checkUser();
    }

    canLoad(state: Route): Observable<boolean> {
        this.logger.debug("canLoad", state);
        return this.checkUser();
    }

    private checkUser(): any {
        this.logger.debug('AuthorizationGuard, canActivate');

        return this.oidcSecurityService.getIsAuthorized().pipe(
            tap((isAuthorized: boolean) => {
                this.logger.debug('AuthorizationGuard, canActivate isAuthorized: ' + isAuthorized);

                if (!isAuthorized) {
                    this.router.navigate(['/']);
                }
            })
        );
    }

    public setAuthKey(authKey) {
        this.logger.debug('set key to', authKey);
        this.authKey = authKey;
    }

    public getAuthKey() {
        return this.authKey;
    }

    public getIsAuthenticated() {
        this.logger.debug('[AuthenticationService.getIsAuthenticated] returning state');
        return this.isAuthenticated;
    }

    public getIsAuthenticatedObservable() {
        return this.oidcSecurityService.getIsAuthorized();
    }
  
    public getToken() {
        this.logger.debug("[AuthenticationService.getToken] returning token");
        return this.oidcSecurityService.getToken();
    }

    public getIdToken() {
        this.logger.debug("[AuthenticationService.getToken] returning id token");
        return this.oidcSecurityService.getIdToken();
    }

    public login() {
        this.logger.debug("[AuthenticationService] login");
        this.oidcSecurityService.authorize();
    }

    public logoff() {
        this.logger.debug("[AuthenticationService] logoff");
        this.oidcSecurityService.logoff();
    }

    initService() {
        this.logger.debug("[AuthenticaionService] init");

        if (window.location.pathname == "/callback") {
            this.cachedLocation = window.location.toString();
            this.logger.debug("[AuthenticaionService] is callback, setting cache to", this.cachedLocation);
        }

        this.oidcSecurityService.getIsModuleSetup().pipe(
            filter((isModuleSetup: boolean) => isModuleSetup),
            take(1)
        ).subscribe((isModuleSetup: boolean) => {
            this.doCallbackLogicIfRequired();
        });
    }

    // Callback with code and token in the window location
    private doCallbackLogicIfRequired() {
        // Will do a callback, if the url has a code and state parameter.
        if (this.cachedLocation !== undefined) {
            this.logger.debug("[AuthenticaionService] (0) using", this.cachedLocation);                
            this.oidcSecurityService.authorizedCallbackWithCode(this.cachedLocation);
            
            this.oidcSecurityService.getIsAuthorized().subscribe(data => {
                this.logger.debug("[AuthenticaionService] is authenticated", data);
                this.isAuthenticated = data;

                if(data){
                    this.logger.debug("[AuthenticaionService] resetting cache", this.cachedLocation);
                    this.cachedLocation = undefined;
                }
            })
        }
    }

}
import { Component, OnDestroy, Inject, ViewChild, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { navItems } from './../../_nav';
import { AuthenticationModal } from '../../components/authentication-modal.module';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { TemperatureService } from '../../services/temperature.service';
import { CurrentTemperature } from '../../models/temperature_current';
import { interval } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnDestroy, OnInit {
  public navItems = navItems;
  public sidebarMinimized = true;
  public element: HTMLElement;

  public authenticateModal;
  public authKey: string;
  public isLoggedIn: boolean;

  public currentTemperature: CurrentTemperature;

  private changes: MutationObserver;
  private interval;

  @ViewChild('childModal') childModal: AuthenticationModal;
  constructor(
    public authService: AuthenticationService,
    public temperatureService: TemperatureService,
    @Inject(DOCUMENT) _document?: any,
  ) {
    this.currentTemperature = {air:0, water:0};

    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = _document.body.classList.contains('sidebar-minimized');
    });
    this.element = _document.body;
    this.changes.observe(this.element, {
      attributes: true,
      attributeFilter: ['class']
    });
    
  }

  ngOnDestroy(): void {
    this.changes.disconnect();
    if (this.interval != null) {
      console.log("Destroyed interval @ default-layout");
      this.interval.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.updateTemperature();
    this.isLoggedInUpdate();
    this.doDataUpdateInterval();
  }

  login() {
    this.authService.login();
  }

  logout() {
    this.authService.logoff();
  }

  doDataUpdateInterval() {
    // check for new values every minute
    this.interval = interval(60000);
    this.interval = this.interval.subscribe(n => {
      console.log("interval triggered @ default-layout");
      this.updateTemperature();
    });
  }

  public updateTemperature() {
    this.temperatureService.getTemperatureCurrent().subscribe(data => {
      this.currentTemperature = data;
      this.currentTemperature.air = Number(this.currentTemperature.air);
    });
  }

  public isLoggedInUpdate() {
    return this.authService.getIsAuthenticatedObservable().subscribe(
      data => {
        console.log('Updated isLoggedIn to ', data);
        this.isLoggedIn = data;
      }
    );
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { CurrentTemperature } from '../models/temperature_current';


@Injectable({
    providedIn: 'root'
})
export class TemperatureService {

    constructor(
        private httpClient: HttpClient
    ) {

    }

    private host = "https://api.maier-eu.at/temp/";

    getTemperatureCurrent(): Observable<CurrentTemperature> {
        return this.httpClient.get<CurrentTemperature>(`${this.host}`);
    }

}
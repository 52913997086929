import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { Observable } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(public auth: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        console.log(`[TokenInterceptor.intercept] intercepted url ${request.url}`);

        // test the cloning
        if(request.url.startsWith("https://api.maier-eu.at/mc")){
            // set the temp hardcoded key
            console.log('minecraft with key', this.auth.getAuthKey());

            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.auth.getAuthKey()}`
                }
            });
        } else if (request.url.startsWith("https://api.maier-eu.at/")) {
            console.log('api.maier with key', this.auth.getToken());

            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.auth.getToken()}`
                }
            });
        }
        
        // test the cloning
        if(request.url.startsWith("https://sso.maier-eu.at")){
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.auth.getToken()}`
                }
            });
        }
        
        if(request.url.startsWith("http://localhost:5000")){
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.auth.getToken()}`
                }
            });
        }


        return next.handle(request);
    }
}
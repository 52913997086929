<div bsModal #authenticateModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="authenticateModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Authenticate</h4>
        <button type="button" class="close" (click)="authenticateModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="fa fa-asterisk"></i></span>
            </div>
            <input type="text" id="key" name="key" class="form-control" placeholder="Authentication Key" [(ngModel)]="authKey">
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="authenticateModal.hide()">Close</button>
        <button type="button" class="btn btn-primary" (click)="saveChanges()">Save changes</button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->